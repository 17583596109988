

export type TDayOfWeek =
    | 'seg'
    | 'ter'
    | 'qua'
    | 'qui'
    | 'sex'
    | 'sab'
    | 'dom'
    ;

export interface DayOfWeekDetail { type: TDayOfWeek; description: { short: string, large: string, normal: string }; }
export const DAY_OF_WEEK: DayOfWeekDetail[] = [
    { type: 'dom', description: { short: 'dom', normal: 'domingo', large: 'domingo' } },
    { type: 'seg', description: { short: 'seg', normal: 'segunda', large: 'segunda' } },
    { type: 'ter', description: { short: 'ter', normal: 'terça', large: 'terça-feira' } },
    { type: 'qua', description: { short: 'qua', normal: 'quarta', large: 'quarta-feira' } },
    { type: 'qui', description: { short: 'qui', normal: 'quinta', large: 'quinta-feira' } },
    { type: 'sex', description: { short: 'sex', normal: 'sexta', large: 'sexta-feira' } },
    { type: 'sab', description: { short: 'sáb', normal: 'sábado', large: 'sábado' } },
];


export type TAppIcons =
    | 'facebook'
    | 'whatsapp'
    | 'instagram'
    | 'carrinho'
    | 'cesta'
    | 'google'
    ;

export type TAppFlags =
    | 'american-express'
    | 'amex'
    | 'credit'
    | 'debit'
    | 'discover'
    | 'elo'
    | 'hipercard'
    | 'jbc'
    | 'master'
    | 'money'
    | 'paypal'
    | 'visa'
    | 'pix'
    ;

export const APP_FLAG_ICONS: Record<TAppFlags, string> = {
    'american-express': 'flag-american-express',
    amex: 'flag-amex',
    credit: 'flag-credit',
    debit: 'flag-debit',
    discover: 'flag-discover',
    elo: 'flag-elo',
    hipercard: 'flag-hipercard',
    jbc: 'flag-jbc',
    master: 'flag-master',
    money: 'flag-money',
    paypal: 'flag-paypal',
    visa: 'flag-visa',
    pix: 'flag-pix',
};


export const APP_LOGO_URL = '/assets/icones/cesta-de-compras.svg';
export const APP_LOGO_URL_DEFAULT = '/assets/logo/azp-logo_imagem.svg';
export const APP_SPINNER = '/assets/spinner/spinner-1s-200px.svg';
export const APP_STORAGE_KEY = 'C4arr1nh0d3c0mPr4S';
export const APP_STORAGE_CARRINHO = 'carrinho';
export const APP_STORAGE_CLIENTE = 'cliente';

export const APP_ICONS: Record<TAppIcons, string> = {
    carrinho: 'carrinho',
    cesta: 'cesta-de-compras',
    facebook: 'facebook',
    google: 'google-color',
    instagram: 'instagram',
    whatsapp: 'whatsapp',
};

export const APP_CLICK_TIMEOUT = 750;
export const APP_CLICK_DEBOUNCE_TIMEOUT = 333;
export const APP_NAME = 'Cestou';


export const APP_CHART_BG_COLOR = [
    '#1f77b4',
    '#d62728',
    '#2ca02c',
    '#092834',
    '#B2D732',
    '#ff7b25',
    '#d64161',
    '#00ffba',
    '#a1e892',
    '#E0CC81',
    '#1050ff',
    '#9900ff'
];

export type TUnidadeVolume = 'L' | 'mL';
export type TUnidadeMassa = 'kg' | 'g';
export type TUnidadeComprimento = 'm' | 'cm' ;

export type TUnidade =
    | 'un'
    | TUnidadeMassa
    | TUnidadeVolume
    | TUnidadeComprimento
    ;

export interface IFlag {
    id: string;
    icon: string;
    nome: string;
    url: string;
}

export interface IUnidade {
    simbolo: TUnidade;
    unidade: IUnidadeTipo;
    prefix: IUnidadePrefix;
}


export interface IUnidadeConf {
    unidade: string;
    name: string;
    min: number;
    max: number;
    step: number;
}


export interface IUnidadeTipo {
    simbolo: string;
    nome: string;
    fator: number;
}

export interface IUnidadePrefix extends IUnidadeTipo {
    prefix: string;
}
/**
 * Prefixos oficiais do SI
 * Os prefixos do SI permitem escrever quantidades sem o uso da notação científica,
 * de maneira mais clara para quem trabalha em uma determinada faixa de valores.
 */
export const UNIDADE_PREFIX = {
    quilo: { prefix: 'quilo', nome: 'quilo', simbolo: 'k', fator: 1000 },
    unidade: { prefix: null, nome: 'unidade', simbolo: null, fator: 1 }, // unidade não existe no Sistema Internacional
    deci: { prefix: 'deci', nome: 'deci', simbolo: 'd', fator: 0.1 },
    centi: { prefix: 'centi', nome: 'centi', simbolo: 'c', fator: 0.01 },
    mili: { prefix: 'mili', nome: 'mili', simbolo: 'm', fator: 0.001 }
};

export const UNIDADE_TIPOS = {
    unidade: { nome: 'unidade', simbolo: 'un', fator: 1 }, // unidade não existe no Sistema Internacional
    litro: { nome: 'litro', simbolo: 'L', fator: 1 },
    grama: { nome: 'grama', simbolo: 'g', fator: 1 },
    duzia: { nome: 'duzia', simbolo: 'dz', fator: 12 },
    metro: { nome: 'metro', simbolo: 'm', fator: 1 }
};

export const UNIDADE_MASSA: Record<TUnidadeMassa, IUnidade> = {
    kg: { simbolo: 'kg', prefix: UNIDADE_PREFIX.quilo, unidade: UNIDADE_TIPOS.grama, },
    g: { simbolo: 'g', prefix: UNIDADE_PREFIX.unidade, unidade: UNIDADE_TIPOS.grama, }
};

export const UNIDADE_VOLUME: Record<TUnidadeVolume, IUnidade> = {
    L: { simbolo: 'L', prefix: UNIDADE_PREFIX.unidade, unidade: UNIDADE_TIPOS.litro, },
    mL: { simbolo: 'mL', prefix: UNIDADE_PREFIX.mili, unidade: UNIDADE_TIPOS.litro, }
};

export const UNIDADE_COMPRIMENTO: Record<TUnidadeComprimento, IUnidade> = {
    m: { simbolo : 'm', prefix: UNIDADE_PREFIX.unidade, unidade: UNIDADE_TIPOS.metro },
    cm: {simbolo: 'cm', prefix: UNIDADE_PREFIX.centi, unidade: UNIDADE_TIPOS.metro },

};

export const UNIDADES: Record<TUnidade, IUnidade> = {
    un: { simbolo: 'un', prefix: UNIDADE_PREFIX.unidade, unidade: UNIDADE_TIPOS.unidade },
    ...UNIDADE_MASSA,
    ...UNIDADE_VOLUME,
    ...UNIDADE_COMPRIMENTO,
    // CX: { name: '', max: 1000, min: 1, step: 1 },
    // LT: { name: '', max: 1000, min: 1, step: 1 },
    // PC: { name: '', max: 1000, min: 1, step: 1 },
    // MT: { name: '', max: 1000, min: 1, step: 1 },
    // UN: { name: '', max: 1000, min: 1, step: 1 },
};

export const FLAGS: Record<TAppFlags, IFlag> = {
    'american-express': { id: 'american-express', icon: 'flag-american-express', nome: 'American Express', url: `/assets/icones/flag-american-express.svg` },
    amex: { id: 'amex', icon: 'flag-amex', nome: 'Amex', url: `/assets/icones/flag-amex.svg` },
    credit: { id: 'credit', icon: 'flag-credit', nome: 'Crédito', url: `/assets/icones/flag-credit.svg` },
    debit: { id: 'debit', icon: 'flag-debit', nome: 'Débito', url: `/assets/icones/flag-debit.svg` },
    discover: { id: 'discover', icon: 'flag-discover', nome: 'Discover', url: `/assets/icones/flag-discover.svg` },
    elo: { id: 'elo', icon: 'flag-elo', nome: 'Elo', url: '/assets/icones/flag-elo.svg' },
    hipercard: { id: 'hipercard', icon: 'flag-hipercard', nome: 'Hipercard', url: '/assets/icones/flag-hipercard.svg' },
    jbc: { id: 'jbc', icon: 'flag-jbc', nome: 'JBC', url: `/assets/icones/flag-jbc.svg` },
    master: { id: 'master', icon: 'flag-master', nome: 'Master', url: `/assets/icones/flag-master.svg` },
    money: { id: 'money', icon: 'flag-money', nome: 'Dinheiro', url: `/assets/icones/flag-money.svg` },
    paypal: { id: 'paypal', icon: 'flag-paypal', nome: 'PayPal', url: `/assets/icones/flag-paypal.svg` },
    visa: { id: 'visa', icon: 'flag-visa', nome: 'Visa', url: `/assets/icones/flag-visa.svg` },
    pix: { id: 'pix', icon: 'flag-pix', nome: 'Pix', url: `/assets/icones/flag-pix.svg` },
};
